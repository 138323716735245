
export const getFormFields = (categoryParents, rulesManagement, rootContext) => [
    { name: 'categ_name', label: 'Nom de catégorie', description: 'Désigne le nom de la catégorie qui permet son identification. eg: la chaine de caractère du breadcrum' },
    { name: 'category_rang', label: 'Rang de catégory', type: 'number', description: 'Désigne le rang de gatégorie de la page active.' },
    { name: 'root_context', label: 'Univers', description: 'Désigne l\'univers contextuel de la page active.' },
    { name: 'url', label: 'URL', description: 'Désigne l\'url du lien pour le maillage' },
    { name: 'anchor', label: 'Anchor text', description: 'L\'anchor du lien pour le maillage.' },
    { name: 'category_parent', label: 'Categorie Parente', type: 'select', options: categoryParents, description: 'Le parent de la page active' },
    { name: 'rules_managment', label: 'Règle de gestion', type: 'select', options: rulesManagement, description: 'Désigne la règle de gestion à appliquer pour la page active' },
    { name: 'score', label: 'Score', type: 'number', description: 'Désigne le poids du lien de cette page pour le maillage interne' },
];
