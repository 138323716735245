import React, { useState } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';  // Style de code avec fond blanc

const DocDev = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert("Code copié !");
        }).catch(() => {
            alert("Échec de la copie");
        });
    };

    const codeExample = `/**
 * worker qui remplace un texte par un autre
 */

addEventListener("fetch", event => {
    event.respondWith(fetchAndReplace(event.request))
})
   
async function fetchAndReplace(request) {
    // Fetch from origin server.
    let response = await fetch(request)
   
    // Make sure we only modify text, not images.
    let type = response.headers.get("Content-Type") || ""
    if (!type.startsWith("text/")) {
        // Not text. Don't modify.
        return response
    }
   
    // Read response body.
    let text = await response.text()
   
    // Modify it.
    let modified = text.replace(
        // mettre le texte à cibler à la place de 'trafic qualifié'
        /trafic qualifié/g, "trafic qui génère des ventes"
    )
   
    // Return modified response.
    return new Response(modified, {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers
    })
}
`;

    return (
        <>
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Documentation Cloudflare Worker SEO</h2>

            <section className="mb-8">
                <h3 className="text-2xl font-semibold text-[orangered] mb-4">Débuter</h3>
                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2">Dossier <strong>Worker-starter</strong></h4>
                    <p className="mb-2">
                        Ce dossier permet d'effectuer les premiers tests pour identifier les éléments du DOM sur le site cible.
                    </p>
                    <p className="mb-4">
                        Cette section de la documentation est axée sur la modification des éléments du DOM :
                        <a href="https://developers.cloudflare.com/workers/runtime-apis/html-rewriter/" className="text-[orangered]"> Cloudflare HTMLRewriter API</a>.
                    </p>

                    {/* Accordion for the code example */}
                    <div className="border border-[orangered] rounded mb-4">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="w-full text-left px-4 py-2 bg-white hover:bg-[rgba(255,69,0,0.2)]">
                            {isOpen ? 'Fermer le code' : 'Afficher le code'}
                        </button>
                        {isOpen && (
                            <div className="relative p-4" style={{ backgroundColor: 'rgba(255,69,0,0.02)' }}>
                                <pre className="overflow-x-auto">
                                    <code
                                        className="text-sm hljs"
                                        dangerouslySetInnerHTML={{ __html: hljs.highlight(codeExample, { language: 'javascript' }).value }}>
                                    </code>
                                </pre>
                                <button
                                    onClick={() => handleCopy(codeExample)}
                                    className="absolute top-2 right-2 bg-[orangered] text-white py-1 px-3 rounded hover:bg-orange-500 transition">
                                    Copier
                                </button>
                            </div>
                        )}
                    </div>
                </article>
            </section>

            <section className="mb-8">
                <h3 className="text-2xl font-semibold text-[orangered] mb-4">Fonctionnement du Worker</h3>
                <ul className="list-disc pl-5 mb-4">
                    <li><strong>Identification</strong> : L’identification d’une page se fait avec son breadcrumb, où le contexte est déduit.</li>
                    <li><strong>Connection avec Supabase</strong> : La connection se fait grâce à l’intégration sur Cloudflare.</li>
                    <li><strong>Vérification</strong> : Le contexte est vérifié grâce au breadcrumb et au champ <code className="bg-gray-100 p-1 rounded">categ_name</code> dans Supabase.</li>
                    <li><strong>Application des règles de gestion</strong> : Les règles de gestion définies sont modifiables depuis la base de données.</li>
                    <li><strong>Affichage des liens pour le maillage interne</strong> : Les liens sont affichés selon les règles de gestion définies dans la base de données.</li>
                </ul>
                <p className="mb-4">
                    Voir au bas du fichier <code className="bg-gray-100 p-1 rounded">doc/Gestion_des_Regles_de_Gestion.pdf</code> un résumé du fonctionnement général du CDN.
                </p>
            </section>

            <section className="mb-8">
                <h3 className="text-2xl font-semibold text-[orangered] mb-4">Supabase + Worker</h3>
                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2"><strong>Storage</strong></h4>
                    <p className="mb-2">Ajouter une intégration <strong>worker+storage</strong> avec Supabase.</p>
                    <p className="mb-4">
                        Créer un compte Supabase au préalable. Ajouter les configurations de connexion de la base de données à Cloudflare en récupérant la clé et l’URL de connexion à l’API de Supabase.
                    </p>
                </article>
                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2"><strong>Data</strong></h4>
                    <p className="mb-2">Pour la gestion des données, se référer à la documentation de Supabase/Cloudflare afin de pouvoir effectuer des requêtes.</p>
                    <p className="mb-4">
                        <a href="https://supabase.com/docs/reference/javascript/initializing" className="text-[orangered]">Documentation Supabase</a>
                    </p>
                </article>
                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2">Gestion du <strong>Cache</strong> dans le <strong>Worker</strong></h4>
                    <p className="mb-2">
                        Pour faire de la mise en cache CDN avec un worker, on peut utiliser la Cache API fournie par Cloudflare Workers. Cela permet de stocker les réponses en cache et de les servir rapidement sans avoir à les récupérer depuis Supabase à chaque fois.
                    </p>
                    <ul className="list-disc pl-5 mb-4">
                        <li>Utiliser la Cache API de Cloudflare : Cloudflare Workers fournit une API pour manipuler le cache directement.</li>
                        <li>Gestion des erreurs : Assurez-vous de gérer correctement les erreurs pour éviter les erreurs internes du serveur.</li>
                        <li>Mettre en cache les réponses : Cachez les réponses des requêtes aux bases de données et servez-les à partir du cache si disponibles.</li>
                    </ul>
                    <p>
                        <a href="https://developers.cloudflare.com/cache/concepts/cdn-cache-control/#when-to-use-cdn-cache-control" className="text-[orangered]">Documentation Cloudflare Cache</a>
                    </p>
                </article>
            </section>

            <section className="mb-8">
                <h3 className="text-2xl font-semibold text-[orangered] mb-4">Administration du Front Office en React</h3>
                <ul className="list-disc pl-5 mb-4">
                    <li><strong>Fonctionnalités</strong> : CRUD de la base de données.</li>
                    <li><strong>Gestion des Users / Auth / Policies</strong> : La sécurité des données se gère directement sur Supabase.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h3 className="text-2xl font-semibold text-[orangered] mb-4">Déploiement du Front Office sur Cloudflare</h3>
                <p className="mb-4">
                    <a href="https://developers.cloudflare.com/pages/framework-guides/deploy-a-react-site/#deploy-with-cloudflare-pages" className="text-[orangered]">Voir la documentation</a>
                </p>
                <h4 className="text-xl font-medium text-gray-700 mb-2">Conditions de Déploiement</h4>
                <ul className="list-disc pl-5 mb-4">
                    {/* eslint-disable-next-line */}
                    <li>Ne pas avoir d’erreurs Eslint ou les échapper en ajoutant la ligne commentée : <code className="bg-gray-100 p-1 rounded">// eslint-disable-next-line</code> au-dessus du code à échapper.</li>
                    <li>Configurer le fichier <code className="bg-gray-100 p-1 rounded">.env</code> pour la connexion avec Supabase en ajoutant les bonnes variables URL & KEY.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h3 className="text-2xl font-semibold text-[orangered] mb-4">Ressources Utiles</h3>
                <ul className="list-disc pl-5 mb-4">
                    <li><a href="https://supabase.com/docs/guides/database/joins-and-nesting" className="text-[orangered]">Faire des requêtes de jointure</a></li>
                    <li><a href="https://supabase.com/docs" className="text-[orangered]">Docs Supabase et Intégrations</a></li>
                </ul>
            </section>
        </>
    );
}

export default DocDev;
