import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/useFetchData';
import Table from '../components/Table';
import Form from '../components/Form';
import supabase from '../hooks/supabaseClient';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ConfirmationModal from '../components/ConfirmationModal';
import useFetchSelectOptions from '../hooks/useFetchSelectOptions';
import { getFormFields } from '../components/utils/MaillageFormFields'; // Import de la fonction
import { maillageValidationSchema } from '../components/utils/ValidationShema'; // Chemin vers votre schéma de validation

const MaillagePage = () => {
    const { data, error, loading, fetchData } = useFetchData('maillage');
    const [isEditing, setIsEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const { categoryParents, rulesManagement, rootContext } = useFetchSelectOptions();

    useEffect(() => {
        fetchData(); // Re-fetch data after mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);



    const handleEdit = (item) => {
        setSelectedItem(item);
        setIsEditing(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setItemToDelete(null);
    };

    const handleDeleteClick = (id) => {
        setItemToDelete(id);
        setModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        setModalOpen(false);
        if (itemToDelete !== null) {
            const { error } = await supabase.from('maillage').delete().eq('id', itemToDelete);
            if (error) {
                console.error('Error deleting item:', error.message);
            } else {
                console.log('Item deleted successfully');
                fetchData(); // Re-fetch data after deletion
            }
            setItemToDelete(null);
        }
    };

    const handleSubmit = async (values) => {
        const { error } = isEditing
            ? await supabase.from('maillage').update(values).eq('id', values.id)
            : await supabase.from('maillage').insert([values]);

        if (error) {
            console.error('Error saving item:', error.message);
        } else {
            setIsEditing(false);
            setSelectedItem(null);
            fetchData(); // Re-fetch data after adding/updating
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setSelectedItem(null);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="contain">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
                <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 sm:mb-0">
                    Gestion des liens pour le maillage
                </h2>
                <div className="flex gap-4">
                    <Link
                        to={'add'}
                        className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-black transition w-full sm:w-auto text-center"
                    >
                        <AddIcon />
                    </Link>
                    <Link
                        to={'/upload-data'}
                        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition w-full sm:w-auto text-center"
                    >
                        Importer CSV <FileUploadIcon />
                    </Link>
                </div>
            </div>
            <div className="overflow-x-auto">
                {isEditing ? (
                    <Form
                        initialValues={selectedItem || {}}
                        fields={getFormFields(categoryParents, rulesManagement, rootContext)}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        validationSchema={maillageValidationSchema}
                    />
                ) : (
                    <Table
                        columns={[
                            'id', 'category_rang', 'url', 'anchor',
                            'category_parent', 'rules_managment', 'score', 'root_context', 'categ_name', 'created_at'
                        ]}
                        data={data}
                        onEdit={handleEdit}
                        onDelete={handleDeleteClick}
                    />
                )}
                <ConfirmationModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmDelete}
                    message="Êtes-vous sûr de vouloir supprimer cet élément ?"
                />
            </div>
        </div>
    );
};

export default MaillagePage;
