import React from "react"

const Home = () => {

    return <>
        <div className="App">
            <h1>Administration des données</h1>
        </div>
    </>
}

export default Home