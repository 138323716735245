import React from 'react';
import CSVUploader from '../components/CsvUpload';

const UploadPage = () => {
  return (
    <div className="upload-page">
      <h1>Upload CSV Data</h1>

      <div className="upload-page-content">
        <div className="upload-section">
          <h2>Liens</h2>
          <p>Upload CSV data for the maillage table:</p>
          <div className="csv-uploader">
            <CSVUploader tableName="maillage" />
          </div>
        </div>
        <div className="upload-section">
          <h2>Règles de Gestion</h2>
          <p>Upload CSV data for the rules_managment table:</p>
          <div className="csv-uploader">
            <CSVUploader tableName="rules_managment" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPage;
