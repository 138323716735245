import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './scss/main.scss';
import './tailwind.css';
import App from './components/App';
import { ProSidebarProvider } from "react-pro-sidebar";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ProSidebarProvider>
      <App />
    </ProSidebarProvider>
  </React.StrictMode>
);