import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmationModal = ({ open, onClose, onConfirm, message }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary" sx={{
          borderColor: 'black', // Couleur de la bordure
          color: 'black', // Couleur du texte
          '&:hover': {
            borderColor: '#1a1a1a', // Couleur de la bordure au survol
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Couleur de fond au survol
          },
        }} >
          Annuler
        </Button>
        <Button onClick={onConfirm} variant="outlined" color="error" sx={{
          borderColor: 'orangered', // Couleur de la bordure
          color: 'orangered', // Couleur du texte
          '&:hover': {
            borderColor: 'orangered', // Couleur de la bordure au survol
            backgroundColor: 'rgba(242, 75, 22, 0.1)', // Couleu// Couleur de fond au survol
          },
        }}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
