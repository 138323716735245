// src/pages/AddRulesManagement.js
import React, { useState } from 'react';
import Form from '../components/Form';
import useFetchSelectOptions from '../hooks/useFetchSelectOptions';
import supabase from '../hooks/supabaseClient';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { getFormFields } from '../components/utils/RulesFormFields';
import { rulesValidationSchema } from '../components/utils/ValidationShema';

const AddRulesManagement = () => {
    const [statusMessage, setStatusMessage] = useState('');
    const [statusType, setStatusType] = useState(''); // 'success' or 'error'
    const navigate = useNavigate();
    const { rootContext, loading, error } = useFetchSelectOptions();

    const handleSubmit = async (values) => {
        const { error } = await supabase.from('rules_managment').insert([values]);

        if (error) {
            console.error('Error adding rules management:', error.message);
            setStatusMessage('Erreur lors de l’ajout des règles de gestion.');
            setStatusType('error');
        } else {
            console.log('Rules Management added successfully');
            setStatusMessage('Les règles de gestion ont été ajoutées avec succès.');
            setStatusType('success');
            navigate('/rules');
        }
    };

    const handleCancel = () => {
        navigate('/rules');
        setStatusMessage(''); // Clear status message on cancel
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Alert severity="error">{`Erreur: ${error.message}`}</Alert>
            </div>
        );
    }

    return (
        <>
            <h2 className="text-2xl font-bold mb-6">Ajout des règles de gestion</h2>
            {statusMessage && (
                <Alert
                    severity={statusType === 'success' ? 'success' : 'error'}
                    className="mb-6"
                >
                    {statusMessage}
                </Alert>
            )}
            <Form
                initialValues={{
                    template: '',
                    url: '',
                    page_type: '',
                    nature: '',
                    links_limit: '',
                    num_blocks: '',
                    insertion_method: '',
                    category_rang: '',
                    context_univers: '',
                    location: '',
                    filiation: ''
                }}
                fields={getFormFields(rootContext)}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                validationSchema={rulesValidationSchema}
            />
        </>
    );
};

export default AddRulesManagement;
