import * as Yup from 'yup';

export const maillageValidationSchema = Yup.object().shape({
    categ_name: Yup.string().required('Nom de catégorie est requis'),
    category_rang: Yup.number().required('Rang de catégorie est requis').positive().integer(),
    root_context: Yup.string().required('Univers est requis'),
    url: Yup.string().url('URL invalide').required('URL est requis'),
    anchor: Yup.string().required('Anchor text est requis'),
    category_parent: Yup.string().required('Catégorie parente est requise'),
    rules_managment: Yup.string().required('Règle de gestion est requise'),
    score: Yup.number().required('Score est requis').positive(),
});


export const rulesValidationSchema = Yup.object().shape({
    template: Yup.string().required('Template est requis'),
    // url: Yup.string().url('URL invalide').required('URL est requise'),
    url: Yup.string().required('URL est requise'),
    page_type: Yup.string().required('Type de Page est requis'),
    nature: Yup.string().required('Nature est requise'),
    links_limit: Yup.number().integer('Doit être un nombre entier').positive('Doit être un nombre positif').required('Nombre de Liens est requis'),
    num_blocks: Yup.number().integer('Doit être un nombre entier').positive('Doit être un nombre positif').required('Nombre de blocs est requis'),
    location: Yup.string().required('Location est requise'),
    insertion_method: Yup.string().oneOf(['before', 'after', 'append'], 'Méthode d\'insertion invalide').required('Méthode d\'insertion est requise'),
    context_univers: Yup.string().required('Univers est requis'),
    filiation: Yup.string().oneOf(['parent', 'oncle', 'grand_parent'], 'Filiation invalide'),
    // .required('Filiation est requise'),
    // category_rang: Yup.array().of(Yup.string().required('Chaque niveau de catégorie est requis')).required('Niveau de catégorie est requis'),
});
