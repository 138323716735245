import React from 'react';
import MUIDataTable from 'mui-datatables';
import Chip from '@mui/material/Chip';
import { TableFilterList } from 'mui-datatables';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';

const CustomChip = ({ label, onDelete }) => (
  <Chip
    variant="outlined"
    color="secondary"
    label={label}
    onDelete={onDelete}
    style={{ marginRight: '5px' }}
  />
);

const CustomFilterList = (props) => <TableFilterList {...props} ItemComponent={CustomChip} />;

const Table = ({ columns, data, onEdit, onDelete }) => {
  // Préparer les colonnes pour MUIDataTable avec cases à cocher et actions
  const columnsWithActions = [
    {
      name: 'select',
      label: 'Select',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateDirection) => (
          <input
            type="checkbox"
            checked={value}
            onChange={() => updateDirection(tableMeta.rowIndex)}
          />
        ),
        // Empêcher l'affichage de la case à cocher pour la sélection de toutes les lignes
        display: 'excluded'
      },
    },
    ...columns,
    {
      name: 'Actions',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const row = data[dataIndex];
          return (
            <div style={{ display: 'flex', gap: '10px' }}>
              {onEdit && (
                // <button
                //   onClick={() => onEdit(row)}
                //   style={{ color: 'black', background: 'transparent', border: 'none', cursor: 'pointer' }}
                // >
                //   <EditNoteIcon/>
                // </button>
                <Button onClick={() => onEdit(row)} variant="outlined" sx={{
                  borderColor: 'black', // Couleur de la bordure
                  color: 'black', // Couleur du texte
                  '&:hover': {
                    borderColor: '#1a1a1a', // Couleur de la bordure au survol
                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Couleur de fond au survol
                  },
                }} >
                  <EditNoteIcon />
                </Button>
              )}
              {onDelete && (
                // <button
                //   onClick={() => onDelete(row.id)}
                //   style={{ color: 'red', background: 'transparent', border: 'none', cursor: 'pointer' }}
                // >
                //   <DeleteIcon />

                // </button>

                <Button
                  onClick={() => onDelete(row.id)}
                  variant="outlined"
                  sx={{
                    borderColor: 'black', // Couleur de la bordure
                    color: 'orangered', // Couleur du texte
                    backgroundColor: '', // Couleur de fond légèrement orange
                    '&:hover': {
                      borderColor: 'orangered', // Couleur de la bordure au survol
                      backgroundColor: 'rgba(242, 75, 22, 0.1)', // Couleur de fond légèrement orange au survol
                    },
                  }}
                >
                  <DeleteIcon />
                </Button>
              )
              }
            </div >
          );
        }
      }
    }
  ];

  // Options de configuration pour MUIDataTable
  const options = {
    filterType: '',
    responsive: 'standard',
    rowsPerPageOptions: [10, 20, 50, 100, 500, 1000],
    pagination: true,
    selectableRows: 'multiple', // Permet la sélection multiple avec les cases à cocher
    download: true,
    print: false,
    viewColumns: true,
    setTableProps: () => ({
      style: {
        overflow: 'hidden',
        maxWidth: '70vw',
        margin: '0 auto',
        // overflow: 'auto',
      },
    }),
  };

  return (
    <MUIDataTable
      // title={" Enregistrements "}
      columns={columnsWithActions}
      data={data}
      components={{
        TableFilterList: CustomFilterList,
      }}
      options={options}
    />
  );
};

export default Table;
