import { Link } from "react-router-dom"

const Error = ( ) => {

    return <>
            <p> Error </p>
            <Link to={'/'}>
            Revenir à l'accueil
            </Link>
    </>
}

export default Error