import { useState, useEffect, useCallback } from 'react';
import supabase from './supabaseClient';

const useFetchData = (tableName, sortBy = 'id') => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Utilisation de useCallback pour mémoriser la fonction fetchData
  const fetchData = useCallback(async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from(tableName)
      .select('*')
      .order(sortBy, { ascending: true });

    if (error) {
      setError(error.message);
    } else {
      setData(data);
    }
    setLoading(false);
  }, [tableName, sortBy]); // Dépendances : tableName, sortBy

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Dépendance : fetchData

  return { data, error, loading, fetchData };
};

export default useFetchData;
