import React, { useState } from 'react';
import Papa from 'papaparse';
import supabase from '../hooks/supabaseClient';
import { Button } from '@mui/material';


const CSVUploader = ({ tableName }) => {
  const [csvData, setCsvData] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setCsvData(results.data);
          setPreviewData(results.data.slice(0, 5)); // Afficher les 5 premières lignes en aperçu
        },
        error: (error) => {
          setError(error.message);
        }
      });
    }
  };

  // const handlePreview = () => {
  //   // Afficher un aperçu des données
  //   // Par exemple, dans un tableau
  // };

  const handleUpload = async () => {
    try {
      const { error } = await supabase.from(tableName).insert(csvData);
      if (error) throw new Error(error.message);
      alert('Data uploaded successfully');
      setCsvData([]);
      setPreviewData([]);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCancel = () => {
    setCsvData([]);
    setPreviewData([]);
    setError(null);
  };

  return (
    <div className="csv-uploader-container">
      <input type="file" accept=".csv" onChange={handleFileChange} />
      {error && <p>Error: {error}</p>}
      {csvData.length > 0 && (
        <>
          {/* <button className="preview" onClick={handlePreview}>Preview Data</button> */}

          <span>   </span>
          <Button onClick={handleUpload} variant="contained" color='success'>
            Importer CSV
          </Button>
          {/* <button className="upload" onClick={handleUpload}>Importer CSV</button> */}
          {/* <button className="cancel" onClick={handleCancel}> Annuler</button> */}
          <Button onClick={handleCancel} variant="outlined" sx={{
            backgroundColor: 'black',
            borderColor: 'black',
            color: 'black',
            '&:hover': {
              borderColor: '#1a1a1a',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}>
            Annuler
          </Button>
          {previewData.length > 0 && (
            <table>
              <thead>
                <tr>
                  {Object.keys(previewData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {previewData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, idx) => (
                      <td key={idx}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default CSVUploader;
