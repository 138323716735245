import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Sidebar from '../components/Side';
import logo from '../assets/images/logo_black.png';
const Layout = () => {
  const { user, loading, signOut } = useAuth();

  if (loading) return <p>Loading...</p>;

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleSignOut = async () => {
    await signOut();
  };

  return (
    <div className="flex flex-col h-screen">
      <header className="bg-black text-white p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold"> <img src={logo} alt="La-bécanerie" /></h1>

        <button
          className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
          onClick={handleSignOut}
        >
          Se déconnecter
        </button>
      </header>
      <div className="flex flex-1 flex-col md:flex-row">
        <Sidebar />

        <main className="flex-1 p-4 contain  ">
          {/* p-4 max-w-3xl mx-auto : class pour centrer le formulaire */}
          {/* <Breadcrumb /> */}
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
