import { useState, useEffect } from 'react';
import supabase from './supabaseClient';

const useFetchSelectOptions = () => {
  const [categoryParents, setCategoryParents] = useState([]);
  const [rootContext, setRootContext] = useState([]);
  const [rulesManagement, setRulesManagement] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
// categoryParents est recursif dans la table vers elle meme donc toutes les enregistrements dont des potentiel parent
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setLoading(true);
        
        // Fetch category parent options
        const { data: categoryParentData, error: categoryParentError } = await supabase
          .from('maillage')
          .select('id, categ_name');

        if (categoryParentError) throw categoryParentError;

        // Fetch root context options and filter out duplicates
        const { data: rootContextData, error: rootContextError } = await supabase
          .from('maillage')
          .select('root_context');

        if (rootContextError) throw rootContextError;

        // Use a Set to filter out duplicate root_context values
        const uniqueRootContexts = Array.from(new Set(rootContextData.map(item => item.root_context)))
          .map(uniqueContext => rootContextData.find(item => item.root_context === uniqueContext));

          
          // Fetch rules management options
          const { data: rulesManagementData, error: rulesManagementError } = await supabase
          .from('rules_managment')
          .select('id, page_type');
          
          if (rulesManagementError) throw rulesManagementError;
          
          const uniqueRuleManagment = Array.from(new Set(rulesManagementData.map(item => item.page_type)))
          .map(uniqueRule => rulesManagementData.find(item => item.page_type === uniqueRule));


        // Set the state with the fetched and filtered data
        setCategoryParents(categoryParentData.map(item => ({ value: item.id, label: item.categ_name })));
        setRootContext(uniqueRootContexts.map(item => ({ value: item.root_context, label: item.root_context })));
        setRulesManagement(uniqueRuleManagment.map(item => ({ value: item.id, label: item.page_type })));

        
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { categoryParents, rootContext, rulesManagement, loading, error };
};

export default useFetchSelectOptions;
