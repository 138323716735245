// src/components/utils/getFormFields.js
export const getFormFields = (rootContext) => [
  { name: 'template', label: 'Template', description: 'Désigne le template de page concerné par la règle de gestion' },
  { name: 'url', label: 'URL', description: 'Désigne le format des urls concernés par cette règle' },
  { name: 'page_type', label: 'Type de Page' },
  { name: 'nature', label: 'Nature' },
  { name: 'links_limit', label: 'Nombre de Liens', type: 'number', description: 'Désigne le nombre de liens à afficher pour cette règle de gestion' },
  { name: 'num_blocks', label: 'Nombre de blocs', type: 'number', description: 'Désigne sur combien de blocs les liens seront affichés' },
  { name: 'location', label: 'Location', description: 'Définira l\'emplacement des liens dans la page et se remplit sous la forme : Un élément E dont la valeur de l\'attribut foo est exactement égale à bar E[foo= "bar"]. Pour plus consultez ce lien : ' },
  {
      name: 'insertion_method', label: 'Méthode d\'insertion', type: 'select', options: [
          { value: 'before', label: 'Before' },
          { value: 'after', label: 'After' },
          { value: 'append', label: 'Append' },
      ], description: 'Désigne la méthode d\'insertion des liens. Eg: avant ou après la section localisée'
  },
  {
      name: 'context_univers',
      label: 'Univers',
      type: 'select',
      options: [
          { value: 'root_context', label: 'Context Courrant' },
          ...rootContext
      ],
      description: 'Sélectionnez le contexte univers.'
  },
  {
      name: 'filiation',
      label: 'Filiation',
      type: 'select',
      options: [
          { value: 'parent', label: 'Parent' },
          { value: 'oncle', label: 'Oncle' },
          { value: 'grand_parent', label: 'Grand Parent' },
      ],
      description: 'Désigne les liens de parentés entre les liens pour le maillage interne.'
  },
  { name: 'category_rang', label: 'Niveau de catégorie', type: 'array' },
];
