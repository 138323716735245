// src/App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import DocAdmin from '../pages/DocAdmin';
import Error from '../pages/Error';
import Login from '../components/Login';
import Layout from '../Layouts/Layout';
import Maillages from '../pages/Maillage';
import RulesManagments from '../pages/RulesManagement';
import AddMaillage from '../pages/AddMaillage';
import AddRulesManagement from '../pages/AddRulesManagment';
import UploadPage from '../pages/UploadCsv';
import DocDev from '../pages/DocDev';
const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />

                {/* Routes protégées */}
                <Route element={<Layout />}>
                    <Route path="/" element={<Home />} />

                    <Route path="/maillage" element={<Maillages />} />
                    <Route path="/rules" element={< RulesManagments />} />
                    <Route path='/maillage/add' element={<AddMaillage />} />
                    <Route path='/rules/add' element={<AddRulesManagement />} />
                    <Route path='upload-data' element={<UploadPage />} />
                    <Route path='administration-dev' element={<DocDev/>}/>
                    <Route path="administration-info" element={<DocAdmin />} />


                    {/* Route par défaut */}
                    <Route path="*" element={<Error />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
