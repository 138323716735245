import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../hooks/supabaseClient';
import { TextField, Button, Typography, Container, Paper, Box, Alert } from '@mui/material';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setError(error.message);
    } else {
      setError(null);
      navigate('/'); // Redirection vers l'accueil après une connexion réussie
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          marginTop: '50px',
          // backgroundColor: '#000', // Fond noir pour le formulaire
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          sx={{ color: 'orangered' }} // Orange pour le titre
        >
          Login
        </Typography>
        <form onSubmit={handleLogin}>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'orangered', // Bordure orange pour les champs
                  },
                  '&:hover fieldset': {
                    borderColor: 'orangered', // Bordure orange au survol
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'orangered', // Couleur du label en orange
                },
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'orangered', // Bordure orange pour les champs
                  },
                  '&:hover fieldset': {
                    borderColor: 'orangered', // Bordure orange au survol
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'orangered', // Couleur du label en orange
                },
              }}
            />
          </Box>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: 'orangered', // Fond orange du bouton
              color: '#000', // Texte en noir pour le bouton
              '&:hover': {
                backgroundColor: 'rgba(242, 75, 22, 0.9)', // Couleur de fond orange au survol
                color: '#FFF', // Texte en blanc au survol
              },
            }}
          >
            Login
          </Button>
          {error && (
            <Box mt={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
