// src/pages/RulesManagement.js
import React, { useState } from 'react';
import useFetchData from '../hooks/useFetchData';
import Table from '../components/Table';
import Form from '../components/Form';
import supabase from '../hooks/supabaseClient';
import { Link } from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/Add';
import ConfirmationModal from '../components/ConfirmationModal';
import useFetchSelectOptions from '../hooks/useFetchSelectOptions';
import { getFormFields } from '../components/utils/RulesFormFields';
import { rulesValidationSchema } from '../components/utils/ValidationShema'
const RulesManagement = () => {
    const { data, error, loading, fetchData } = useFetchData('rules_managment', 'id');
    const { rootContext } = useFetchSelectOptions();

    const [isEditing, setIsEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);


    const handleEdit = (item) => {
        setSelectedItem(item);
        setIsEditing(true);
    };

    const handleDeleteClick = (id) => {
        setItemToDelete(id);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setItemToDelete(null);
    };

    const handleConfirmDelete = async () => {
        setModalOpen(false);
        if (itemToDelete !== null) {
            const { error } = await supabase.from('rules_managment').delete().eq('id', itemToDelete);
            if (error) {
                console.error('Error deleting item:', error.message);
            } else {
                fetchData(); // Re-fetch data to update the table
            }
            setItemToDelete(null);
        }
    };

    const handleSubmit = async (values) => {
        const { error } = isEditing
            ? await supabase.from('rules_managment').update(values).eq('id', values.id)
            : await supabase.from('rules_managment').insert([values]);

        if (error) {
            console.error('Error saving item:', error.message);
        } else {
            setIsEditing(false);
            setSelectedItem(null);
            fetchData(); // Re-fetch data to update the table
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setSelectedItem(null);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="contain">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
                <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 sm:mb-0">
                    Règles de gestion
                </h2>
                <div className="flex gap-4">
                    <Link
                        to={'add'}
                        className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition w-full sm:w-auto text-center"
                    >
                        <AddIcon />
                    </Link>
                    <Link
                        to={'/upload-data'}
                        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition w-full sm:w-auto text-center"
                    >
                        Importer CSV <FileUploadIcon />
                    </Link>
                </div>
            </div>
            <div className="overflow-x-auto">
                {isEditing ? (
                    <Form
                        initialValues={selectedItem || { category_rang: [] }} // Ensure category_rang is initialized as an array
                        fields={getFormFields(rootContext)}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        validationSchema={rulesValidationSchema}
                    />
                ) : (
                    <Table
                        columns={[
                            'id', 'template', 'url', 'page_type', 'nature',
                            'created_at', 'links_limit', 'num_blocks', 'insertion_method',
                            'category_rang', 'context_univers', 'location', 'filiation'
                        ]}
                        data={data}
                        onEdit={handleEdit}
                        onDelete={handleDeleteClick}
                    />
                )}
            </div>
            <ConfirmationModal
                open={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDelete}
                message="Êtes-vous sûr de vouloir supprimer cet élément ?"
            />
        </div>
    );
};

export default RulesManagement;
